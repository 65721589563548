@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-Thin.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-ExtraLight.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-Light.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-Regular.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-Medium.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-SemiBold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-Bold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-ExtraBold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Epilogue";

  src: url("./assets/fonts/Epilogue-Black.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-Thin.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-Light.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-Medium.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-Bold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";

  src: url("./assets/fonts/Inter-Black.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Inter, Roboto, 'Helvetica Neue', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F7F6;
}

body * {
  box-sizing: border-box;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #029094;
  color: #029094;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #029094;
  color: #029094;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #029094;
  }
  50%,
  100% {
    background-color: rgba(2, 144, 148, 0.31);
  }
}

.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width: 100px;
  height: 100px;
}

.cr-load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite
}

.cr-load :first-child {
  background: #5ECB9E;
  animation-delay: -1.5s
}

.cr-load :nth-child(2) {
  background: #029094;
  animation-delay: -1s
}

.cr-load :nth-child(3) {
  background: #5ECB9E;
  animation-delay: -0.5s
}

.cr-load :last-child {
  background: #029094
}

@keyframes spin {
  0%, 100% {
    transform: translate(0)
  }
  25% {
    transform: translate(160%)
  }
  50% {
    transform: translate(160%, 160%)
  }
  75% {
    transform: translate(0, 160%)
  }
}

.preloader {
  min-width: 100px;
  min-height: 100px;
}

.preloader hr {
  border: 0;
  margin: 0;
  width: 50%;
  height: 50%;
  position: absolute;
  border-radius: 10px;
  animation: spin 6s ease infinite
}

.preloader :first-child {
  background: #1C434F;
  animation-delay: -4s
}

.preloader :nth-child(2) {
  background: #D08B7E;
  animation-delay: -2s
}

.preloader :nth-child(3) {
  background: #F9E69A;
}

@keyframes spin {
  0%, 100% {
    transform: translate(0)
  }
  25% {
    transform: translate(130%)
  }
  50% {
    transform: translate(130%, 130%)
  }
  75% {
    transform: translate(0, 130%)
  }
}
